import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Grid,
  Button,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  ListItemText,
  useMediaQuery
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import { useStyles } from './contactForm.styles';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import { useNotification } from '../../hooks/useNotification';
import { toggleLoader, setIsFormServices } from '../../store/appSlice';
import { useResetFormServices } from '../../hooks/useResetFormServices';

// form validation
const phoneRegExp = /^$|^[0-9 \+\.\-\(\)\/]{3,}$/;
const validationSchema = Yup.object().shape({
  fullname: Yup.string().required('Le nom est requis'),
  tel: Yup.string().matches(phoneRegExp, 'Le numéro est invalide'),
  email: Yup.string().email('L\'e-mail est invalide')
    .required('L\'adresse e-mail est requise'),
  message: Yup.string().required('Le message est requis'),
});

export function ContactForm() {
  const dispatch = useDispatch();
  const { isFormServices } = useSelector(state => state.app);
  const { siteNavigation } = useSiteMetadata();
  const { showNotif } = useNotification();
  const { resetFormServices } = useResetFormServices();

  // handle fidle size
  const [fieldSize, setFieldSize] = useState('');
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

  useEffect(() => {
    matches ? setFieldSize('medium') : setFieldSize('small');
  }, [matches]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleServicesChange = e => {
    const value = e.target.value;
    dispatch(setIsFormServices(typeof value === 'string' ? value.split(',') : value));
  };

  return (
    <Formik
      initialValues={{
        fullname: '',
        society: '',
        tel: '',
        email: '',
        services: isFormServices,
        message: '',
      }}
      validationSchema={validationSchema}
      // backend validation
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        dispatch(toggleLoader(true));

        const req = await fetch('/api/contact', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(values),
        });

        const res = await req.json();

        if (res.type === 'success') {
          setSubmitting(false);
          dispatch(toggleLoader(false));
          resetFormServices();
          resetForm();
          showNotif('Merci de nous avoir contacté ! Nous reviendrons vers vous dans les plus brefs délais.', 'success');
        }
        else {
          setSubmitting(false);
          dispatch(toggleLoader(false));
          showNotif(`Une erreur s'est produite. ${res.reason}. Veuillez s'il vous plaît réessayer.`, 'error');
        }
      }}
    >
      {({ errors, handleChange, touched, values }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size={fieldSize}
                name="fullname"
                label="Nom *"
                type="text"
                variant="filled"
                value={values.fullname}
                onChange={handleChange}
                error={touched.fullname && errors.fullname ? true : false}
                helperText={touched.fullname && errors.fullname ? errors.fullname : null}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size={fieldSize}
                name="email"
                label="E-mail *"
                type="email"
                variant="filled"
                value={values.email}
                onChange={handleChange}
                error={touched.email && errors.email ? true : false}
                helperText={touched.email && errors.email ? errors.email : null}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size={fieldSize}
                name="tel"
                label="Téléphone"
                type="tel"
                variant="filled"
                value={values.tel}
                onChange={handleChange}
                error={touched.tel && errors.tel ? true : false}
                helperText={touched.tel && errors.tel ? errors.tel : null}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size={fieldSize}
                name="society"
                label="Société"
                type="text"
                variant="filled"
                value={values.society}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                size={fieldSize}
                variant="filled"
              >
                <InputLabel>Service(s) concerné(s)</InputLabel>
                <Select
                  multiple
                  name="services"
                  MenuProps={MenuProps}
                  label="Service(s) concerné(s)"
                  value={values.services}
                  onChange={e => {
                    handleChange(e);
                    handleServicesChange(e);
                  }}
                  sx={{ ...useStyles.servicesSelection }}
                  renderValue={selected => selected.join(', ')}
                >
                  {siteNavigation.services.map((item, i) => (
                    <MenuItem
                      key={i}
                      value={item.title}
                      sx={{ ...useStyles.servicesMenu }}
                    >
                      <Checkbox checked={values.services.indexOf(item.title) > -1} />
                      <ListItemText primary={item.title} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size={fieldSize}
                multiline
                rows="6"
                name="message"
                label="Message *"
                variant="filled"
                value={values.message}
                onChange={handleChange}
                error={touched.message && errors.message ? true : false}
                helperText={touched.message && errors.message ? errors.message : null}
              />
            </Grid>
            <Grid item xs={12} align="left">
              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ ...useStyles.button }}
              >
                Envoyer
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};