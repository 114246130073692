import React, { useState, useCallback, useMemo } from 'react';
import { Button } from 'gatsby-theme-material-ui';

import {
  Popup,
  Marker,
  TileLayer,
  MapContainer
} from 'react-leaflet';
import { Box, Stack } from '@mui/material';
import { RoomRounded, FullscreenRounded } from '@mui/icons-material';

import { useStyles } from './map.styles';
import { TextContent } from '../utils/utils';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';

// target point
const zoom = 14.5;

// use extern hook to prevent WebpackError in order to use map click handler
function ButtonsMap({ map, center, url }) {
  const handleCenter = useCallback(() => {
    map.setView(center, zoom);
  }, [map, center]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ ...useStyles.buttonWrap }}
    >
      <Button
        onClick={handleCenter}
        startIcon={<RoomRounded />}
      >
        Recentrer
      </Button>
      <Button
        to={url}
        target="_blank"
        underline="none"
        rel="noopener noreferrer"
        startIcon={<FullscreenRounded />}
      >
        Agrandir
      </Button>
    </Stack>
  );
};

export function Map({ center, url }) {
  const { author } = useSiteMetadata();

  const [map, setMap] = useState(null);

  const displayMap = useMemo(() => (
    <Box sx={{ ...useStyles.map }}>
      <MapContainer
        zoom={zoom}
        center={center}
        whenCreated={setMap}
      >
        {/* copyright */}
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright" rel="nofollow noopener noreferrer">OpenStreetMap</a> contributors'
        />

        {/* target point */}
        <Marker position={center}>
          <Popup>{author}</Popup>
        </Marker>
      </MapContainer>
    </Box>
  ), [map]);

  return (
    <>
      {/* prevent WebpackError */}
      {typeof window !== 'undefined' ? (
        <>
          {displayMap}
          {map ? (
            <ButtonsMap
              map={map}
              url={url}
              center={center}
            />
          ) : (
            null
          )}
        </>
      ) : (
        <TextContent>La carte n'est pas disponible.</TextContent>
      )}
    </>
  );
};