import { customization } from '../layout/Theme';

export const useStyles = {
  titleWrapper: {
    backgroundColor: 'background.default',
  },

  titleWrap: {
    pt: {
      xs: '7.5vh',
      sm: 4,
    },
    px: {
      xs: 0,
      sm: 8,
    },
    mt: {
      xs: 0,
      sm: -11,
    },
    textAlign: 'center',
    display: 'inline-block',
    backgroundColor: 'background.default',
    borderRadius: `${customization.borderRadius.large}px ${customization.borderRadius.large}px 0 0`,
  },

  formWrapper: {
    pt: {
      xs: '5vh !important',
      sm: '10vh !important',
    },
  },
};