import React from 'react';

import { Box, Stack } from '@mui/material';

import { Map } from './Map';
import { Title } from '../utils/utils';
import { Reveal } from '../../hooks/reveal';
import { useStyles } from './findUs.styles';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';

export function FindUs() {
  const { company } = useSiteMetadata();

  return (
    <Box component="section" sx={{ ...useStyles.root }}>
      <Reveal>
        <Stack direction="column" alignItems="center">
          <Title>Où nous trouver</Title>
          <Map
            url={company.fribourg.googleMapsUrl}
            center={company.fribourg.googleMapsTarget}
          />
        </Stack>
      </Reveal>
    </Box>
  );
};