import React from 'react';

import { Box, Stack } from '@mui/material';

import { Reveal } from '../hooks/reveal';
import { Hero } from '../components/hero/Hero';
import { useStyles } from './ContactView.styles';
import { FindUs } from '../components/map/FindUs';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { ContactForm } from '../components/forms/ContactForm';
import { TitleBig, Subtitle } from '../components/utils/utils';

export default function ContactView() {
  const { author } = useSiteMetadata();

  return (
    <>
      <Hero
        isSlider={false}
        images={[{
          filename: 'contact1.jpg',
          alt: `A view of a smartphone screen displaying the ${author} logo`,
        }]}
      />
      <Reveal>
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ ...useStyles.titleWrapper }}
        >
          <Box sx={{ ...useStyles.titleWrap }}>
            <TitleBig>Contactez-nous !</TitleBig>
          </Box>
        </Stack>
      </Reveal>
      <Box
        component="section"
        className="centered"
        sx={{ ...useStyles.formWrapper }}
      >
        <Reveal>
          <Box mb={5}>
            <Subtitle component="p" className="regular">
              Besoin de plus d’informations ? Envoyez-nous votre demande, nous nous ferons un plaisir de vous répondre !
            </Subtitle>
          </Box>
        </Reveal>
        <Reveal>
          <ContactForm />
        </Reveal>
      </Box>
      <FindUs />
    </>
  );
};