import React from 'react';

import Seo from '../components/seo/Seo';
import ContactView from '../views/ContactView';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

export default function Contact() {
  const { siteNavigation } = useSiteMetadata();

  return (
    <>
      <Seo
        currentTitle={siteNavigation.links[siteNavigation.links.length -1].title}
        currentDescription={siteNavigation.links[siteNavigation.links.length -1].description}
      />
      <ContactView />
    </>
  );
};
