export const useStyles = {
  button: {
    fontSize: theme => theme.typography.pxToRem(16),
  },

  servicesSelection: {
    textAlign: 'left',
  },

  servicesMenu: {
    p: 0,
    pl: 0.5,
  },

};