import { customization } from '../../layout/Theme';

export const useStyles = {
  map: {
    width: '100%',

    '& .leaflet-container': {
      height: {
        xs: '45vh',
        sm: '35vh',
      },
      zIndex: 1, // behind nav overlay
      borderRadius: customization.borderRadius.small,
    },

    '& .leaflet-popup-content-wrapper': {
      borderRadius: `${customization.borderRadius.medium}px !important`,
    },

    '& .leaflet-popup-close-button': {
      display: 'none',
    },
  },

  buttonWrap: {
    mt: 1,
    width: '100%',
    
    '& a, & button': {
      color: 'text.primary',
    },
  },
};